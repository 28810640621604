<template>
  <div>
    <div class="mb-2">
      <b-button variant="primary-custom" @click="$router.push('/empresas')">
        <fa-icon icon="arrow-left" class="mr-2"></fa-icon> Voltar
      </b-button>
    </div>
    <TableList :data="data" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      unityId: this.$route.params.unityId,
    };
  },
  components: {
    TableList: () => import("./components/TableList"),
  },
  computed: {
    data() {
      return this.$store.state.unities.transferRateList;
    },
  },
  methods: {
    async get_unity_transfer_rate(unityId) {
      const response = await this.$store.dispatch("http_get", {
        url: `/unity/${unityId}/transfer-rate`,
        vuex: "unities/transferRateList",
      });
      console.log(response);
    },
  },
  async mounted() {
    await this.get_unity_transfer_rate(this.unityId);
  },
};
</script>

<style></style>
